import React, { useMemo, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { HiOutlineClock } from 'react-icons/hi';
import { IoIosArrowDown, IoMdCar } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { MdAttachMoney } from 'react-icons/md';
import {
  Box,
  Center,
  Collapse,
  Divider,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { colors } from 'theme/components/colors';

interface NotificationProps {
  onClose?: () => void;
  onNavigate?: () => void;
  title: string;
  content: string;
  type: string;
  icon: string | number;
}

export const Notification: React.FC<NotificationProps> = ({
  onClose,
  onNavigate,
  title,
  content,
  type,
  icon,
}) => {
  const defaultOptions = {
    bg: 'transparent',
    boxBg: 'transparent',
    w: '40px',
    h: '40px',
  };

  const [showMore, setShowMore] = useState<boolean>(false);

  const iconOptions = useMemo(() => {
    switch (icon) {
      case 'time':
        return {
          icon: HiOutlineClock,
          color: 'text.500',
          ...defaultOptions,
        };
      case 'money':
        return {
          icon: MdAttachMoney,
          color: 'text.500',
          ...defaultOptions,
        };
      case 'car':
        return { icon: IoMdCar, color: 'text.500', ...defaultOptions };
      case 'check':
        return {
          icon: AiOutlineCheck,
          color: 'white',
          bg: 'green.1000',
          boxBg: 'transparent',
          w: '20px',
          h: '20px',
        };

      default:
        return {
          icon: AiOutlineCheck,
          color: 'white',
          bg: 'green.1000',
          boxBg: 'green.200',
          w: '20px',
          h: '20px',
        };
    }
  }, [icon]);

  return (
    <Flex
      w="100%"
      borderRadius={type === 'central' ? '0px' : '10px'}
      alignItems="center"
      justifyContent="space-between"
      p={3}
      bg="white"
      borderBottom={type === 'central' ? 'none' : `8px ${colors.button} solid`}
    >
      <Flex alignItems="center">
        {onClose && (
          <Center
            mr="8px"
            borderRadius="full"
            p="4px"
            bg="grey.200"
            position="absolute"
            left={0}
            cursor="pointer"
            mb="64px"
            onClick={onClose}
          >
            <Icon
              as={IoClose}
              color="black"
              h="16px"
              w="16px"
              alignSelf="center"
            />
          </Center>
        )}

        <Center
          w="52px"
          mr="8px"
          borderRadius="8px"
          py="12px"
          bg={iconOptions.boxBg}
        >
          <Icon
            as={iconOptions.icon}
            color={iconOptions.color}
            h={iconOptions.h}
            w={iconOptions.w}
            p="2px"
            borderRadius="full"
            alignSelf="center"
            bg={iconOptions.bg}
          />
        </Center>
        <Box>
          <Text
            noOfLines={1}
            fontWeight={600}
            fontSize="18px"
            textColor="text.100"
          >
            {title}
          </Text>
          <Flex w="100%" justifyContent="space-between">
            <Collapse startingHeight={20} in={showMore}>
              <Text
                h="72px"
                noOfLines={showMore ? 3 : 1}
                fontSize="16px"
                textColor="text.100"
              >
                {content}
              </Text>
            </Collapse>
            {type === 'central' && content?.length > 80 && (
              <Flex
                onClick={() => setShowMore(show => !show)}
                alignItems="center"
                minW="80px"
                ml="16px"
              >
                <Text fontSize="14px" textColor="blue.0">
                  ver mais
                </Text>
                <Icon
                  ml="8px"
                  as={IoIosArrowDown}
                  h="16px"
                  w="16px"
                  color="blue.0"
                  transition="all .5s ease"
                  transform={showMore ? 'rotate(-180deg)' : 'rotate(-360deg)'}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Divider
          ml="8px"
          mr={type === 'central' ? '24px' : '16px'}
          aria-hidden="true"
          borderWidth="1px"
          borderColor="grey.600"
          opacity="0.2"
          orientation="vertical"
          h="52px"
        />
        <Text
          mr={type === 'central' ? '12px' : '0px'}
          onClick={onNavigate}
          fontSize="20px"
          textColor="mutable.100"
          cursor="pointer"
        >
          Conferir
        </Text>
      </Flex>
    </Flex>
  );
};
