import React, { useCallback } from 'react';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { MdOutlineImage } from 'react-icons/md';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Box,
  ModalHeader,
  Flex,
  Icon,
  ModalCloseButton,
} from '@chakra-ui/react';

interface FgtPwModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: string;
}

export const PDFmodal = ({ isOpen, onClose, file }: FgtPwModalProps) => {
  const PdfViewer = useCallback(() => {
    return (
      <embed src={file} type="application/pdf" width="100%" height="100%" />
    );
  }, [file]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderTopRadius="8px"
        h="94%"
        mb="0px"
        minW={{ base: '100%', md: '80%' }}
        bg="white"
      >
        <ModalHeader minH="32px">
          <ModalCloseButton color="black" />
        </ModalHeader>
        <ModalBody py="24px" pb="24px" mb="0px" textAlign="center">
          <PdfViewer />
        </ModalBody>

        <ModalFooter justifyContent="center" pt="0px" mt="20px" pb="24px">
          <Flex w="100%" justifyContent="space-between">
            <Box>
              <Text color="text.100">Anexos</Text>
              <Flex alignItems="center" gap="8px">
                <Icon
                  w="18px"
                  h="16px"
                  as={BsFileEarmarkRichtext}
                  color="blue.0"
                />
                <Text fontSize="14px" color="blue.0">
                  Documento.pdf
                </Text>
              </Flex>
              <Flex alignItems="center" gap="8px">
                <Icon w="18px" h="18px" as={MdOutlineImage} color="blue.0" />
                <Text fontSize="14px" color="blue.0">
                  Imagem.jpeg
                </Text>
              </Flex>
              <Text color="blue.0"></Text>
            </Box>
            <Flex alignSelf="end">
              <Button onClick={onClose} variant="outline" mr="18px">
                Fechar
              </Button>
              <Button onClick={() => null}>Conferir</Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
