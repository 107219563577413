import React, { useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { MdOutlineImage } from 'react-icons/md';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import pdf from 'assets/files/exemplo.pdf';
import { Card, SimpleInput, Tag } from 'components';
import { Face } from './components/face';
import { HappyFace } from './components/happyFace';
import { PDFmodal } from './components/PdfModal';
import { SadFace } from './components/sadFace';

interface CommuniqueItemProps {
  cat?: string;
  title?: string;
  desc?: string;
  startDate?: string;
  thumb?: ImageProps | null;
}

export const CommuniqueItem: React.FC<CommuniqueItemProps> = ({
  title,
  startDate,
  desc,
  cat,
  thumb,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showMore, setShowMore] = useState<boolean>(false);
  const [hovered, setHovered] = useState<string | null>('');
  const [picked, setPicked] = useState<string | null>('');
  const [feedback, setFeedback] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);

  return (
    <VStack w={{ base: '260px', sm: '336px' }} maxW="336px" shadow="md">
      <PDFmodal isOpen={isOpen} onClose={onClose} file={pdf} />
      <Card p="10px" justifyContent="start" minW="100%">
        <Flex gap="8px" alignItems="center">
          <Tag fontSize="12px" label={cat || 'Novidade'} />
          <Text fontSize="10px" textColor="grey.600">
            {startDate || '08 nov. 2023'}
          </Text>
        </Flex>
        <Text fontWeight={500} mt="8px" textColor="text.100">
          {title || 'Título'}
        </Text>
        {thumb?.path ? (
          <Image my="14px" h="160px" src={thumb.path} />
        ) : (
          <Center my="14px" bg="grey.1000" h="160px">
            <Icon w="52px" h="52px" as={MdOutlineImage} />
          </Center>
        )}
        <Box
          transition="all 0.5s"
          overflow="hidden"
          maxH={showMore ? '140px' : '70px'}
        >
          <Text
            noOfLines={10}
            lineHeight="14px"
            fontSize="10px"
            textColor="text.100"
          >
            {desc ||
              'Lorem ipsum dolor sit amet consectetur. Facilisi mattis leo eu ametet. Vel pulvinar rhoncus non scelerisque gravida est. Nisl quam at  sollicitudin non est quis nunc viverra. Tincidunt quisque purus  commodo sed libero elementum neque. Tincidunt quam sit aliquam  arcu tellus aenean adipiscing aenean. Et id dui ut cursus.'}
          </Text>
        </Box>
        {desc && desc?.length > 328 && (
          <Flex
            justifyContent="center"
            gap="4px"
            mt="10px"
            alignItems="center"
            onClick={() => setShowMore(show => !show)}
          >
            <Text textAlign="center" fontSize="14px" textColor="blue.0">
              ler mais
            </Text>
            <ChevronDownIcon
              transition="all 0.5s"
              transform={showMore ? 'rotate(360deg)' : 'rotate(180deg)'}
              w="18px"
              h="18px"
              color="blue.0"
            />
          </Flex>
        )}

        <Button
          onClick={onOpen}
          borderWidth="1.5px"
          fontWeight={400}
          mb="20px"
          mt="10px"
          variant="outline"
        >
          Confira
        </Button>
      </Card>
      <Center
        boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
        h="60px"
        w="100%"
        p="4px"
        mt="-8px"
        bg="grey.1100"
      >
        <Flex alignItems="center" position="absolute" mb="62px" ml="0px">
          <Box
            cursor="pointer"
            onClick={() =>
              setPicked(picked => (picked === 'sad' ? null : 'sad'))
            }
            onMouseOver={() =>
              !hovered && picked !== 'sad' && setHovered('sad')
            }
            onMouseLeave={() => hovered && setHovered(null)}
          >
            <SadFace
              isOutline={
                hovered === 'sad' || (!hovered && !picked) || picked === 'sad'
              }
            />
          </Box>
          <Box
            cursor="pointer"
            onClick={() =>
              setPicked(picked => (picked === 'face' ? null : 'face'))
            }
            onMouseOver={() =>
              !hovered && picked !== 'face' && setHovered('face')
            }
            onMouseLeave={() => hovered && setHovered(null)}
          >
            <Face
              isOutline={
                hovered === 'face' || (!hovered && !picked) || picked === 'face'
              }
            />
          </Box>
          <Box
            cursor="pointer"
            onClick={() =>
              setPicked(picked => (picked === 'happy' ? null : 'happy'))
            }
            onMouseOver={() =>
              !hovered && picked !== 'happy' && setHovered('happy')
            }
            onMouseLeave={() => hovered && setHovered(null)}
          >
            <HappyFace
              isOutline={
                hovered === 'happy' ||
                (!hovered && !picked) ||
                picked === 'happy'
              }
            />
          </Box>
        </Flex>
        {sent ? (
          <Flex gap="4px" alignItems="center">
            <Icon w="18px" h="18px" color="green.300" as={IoMdCheckmark} />
            <Text fontSize="12px" textColor="grey.600">
              Obrigado pelo feedback!
            </Text>
          </Flex>
        ) : (
          <SimpleInput
            setSent={() => setSent(true)}
            onChange={e => setFeedback(e.target.value)}
            value={feedback}
            type={feedback.length > 5 ? 'sent' : ''}
            mx="auto"
            w="314px"
            h="28px"
            fontSize="12px"
            placeholder="Nos envie seu feedback"
          />
        )}
      </Center>
    </VStack>
  );
};
