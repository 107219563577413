import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

interface CardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CardModal = ({ isOpen, onClose }: CardModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w={{ base: '80%', md: '445px' }}
        py="24px"
        px="18px"
        bg="white"
      >
        <ModalHeader
          p="0px"
          fontSize="16PX"
          fontWeight={800}
          color="ocean.100"
          alignSelf="start"
        >
          Entre em contato
        </ModalHeader>
        <ModalCloseButton color="black" />

        <ModalBody
          w={{ base: '100%', md: '330px' }}
          mt="12px"
          py="0px "
          px="0px"
          fontSize="12px"
          textAlign="start"
        >
          <Text color="text.400">
            O seu plano atual <strong>não inclui frota reserva</strong>. Para
            saber mais sobre, entre em contato pelo telefone abaixo:
            <br />
            <br />
            Deseja uma cotação de veículo reserva ?
            <br /> Ligue para <strong>0800 025 4141</strong>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
