export const moneyFormatter = (amount: number | null) => {
  if (!amount) return '';
  const amountFixed = amount.toFixed(2).replace('.', ',');
  const amountSliced = amountFixed.split(',');
  return `R$ ${amountSliced[0].replace(/\d+?(?=(?:\d{3})+$)/gim, '$&.')},${
    amountSliced[1]
  }`;
};

export const urlFormatter = (path: string) => {
  const pathWithoutId = path.includes('manutencao')
    ? path
    : path.replace(/^(\/[^/]+\/[^/]+).*$/, '$1');

  switch (pathWithoutId) {
    case '/inicio':
      return 'Início';
    case '/relatorios/modelos':
      return 'Modelos';
    case '/relatorios/distdefrotas':
      return 'Distribuição da Frota';
    case '/relatorios/manutencao/entradasaida':
      return 'Entrada e Saída';
    case '/relatorios/manutencao/avarias':
      return 'Avarias';
    case '/relatorios/manutencao/servicos':
      return 'Serviços';
    case '/relatorios/manutencao/preventivas':
      return 'Preventivas';
    case '/relatorios/disponibilidade':
      return 'Disponibilidade';
    case '/relatorios/distribuicaodafrota':
      return 'Distribuição da Frota';
    case '/agendamentos':
      return 'Agendamentos';
    case '/novo-agendamento/veiculos':
    case '/novo-agendamento/servicos':
    case '/novo-agendamento/agenda':
    case '/novo-agendamento/lote':
    case '/novo-agendamento/revisao':
    case '/novo-agendamento/imagens':
      return 'Novo Agendamento';
    case '/faturamento':
      return 'Faturamento';
    case '/faturamento/mediacao':
      return 'Mediação';
    case '/acessos':
      return 'Acessos';
    case '/acessos/usuario':
      return 'Criação de usuário';
    case '/centraldeduvidas':
      return 'Canais de atendimento';
    case '/centraldeduvidas/pergunta':
      return 'Pergunta';
    case '/centraldeduvidas/pergunta/criar':
      return 'Criar pergunta';
    case '/centraldeduvidas/pergunta/editar':
      return 'Editar pergunta';
    case '/centraldeduvidas/lista':
      return 'Lista de perguntas';
    case '/centraldeduvidas/editarcategorias':
      return 'Editar categorias';
    case '/gestaodecontratos':
      return 'Gestão de contratos';
    case '/gestaodecontratos/contrato':
      return 'Contrato';
    case '/logs':
      return 'Logs';
    case '/gestao/veiculos':
      return 'Veículos';
    case '/gestao/cobrancas':
      return 'Cobranças';
    case '/gestao/multas':
      return 'Multas';
    case '/gestao/multa':
      return 'Indicar condutor';
    case '/comunicados/lista':
      return 'Comunicados';
    case '/comunicados/comunicado':
      return 'Comunicado';
    default:
      return '';
  }
};

export const actionFormatter = (action: string) => {
  switch (action) {
    case 'Criou conteúdo':
      return '2';
    case 'Alterou conteúdo':
      return '4';
    case 'Deletou conteúdo':
      return '8';
    case 'Cancelamento':
      return '16';
    case 'Baixou conteúdo':
      return '32';
    case 'Aprovação Fatura':
      return '64';
    case 'Contestação Fatura':
      return '128';
    case 'CRLV não Encontrado':
      return '256';
    case 'CRLV em Lote':
      return '512';
  }
};

export const sectionFormatter = (action: string) => {
  switch (action) {
    case 'Agendamento':
      return '0';
    case 'Central de Ajuda':
      return '1';
    case 'Faturamento':
      return '2';
    case 'CRLV':
      return '3';
  }
};

export const handleCustomUrl = (title: string) => {
  return title.trim().replace(/[^\w\s]/gi, '');
};

export const handleEconomic = (text?: string) => {
  if (!text) return '';

  if (text === 'VAMOS LOCACAO DE CAM. MAQUINAS E EQUI. S/A') return 'VL S/A';
  else
    return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
};
