import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  AddsNameAndImage,
  AddsWidgetAndColor,
  ChangeSizeAndOrder,
  Documents,
  FilterOnboarding,
  FinOnboarding,
  Fines,
  HelpImage,
  Logs,
  Money,
  OnboardingImage1,
  Reports,
  Schedule,
  TourVideo2,
  Vehicle,
  TourVideo,
  Thumb,
  Thumb2,
} from 'assets';

export const dashboardTexts = {
  step1: {
    title: 'Boas-vindas ao novo Portal do Cliente da Vamos!',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui você encontra as informações e serviços que permitem uma gestão
        eficiente das frotas com mais <strong>tecnologia e inovação</strong>.
      </Text>
    ),
    thumb: Thumb,
    video: TourVideo,
    first: true,
    isModal: true,
  },
  step2: {
    title: 'Aproveite seu onboarding!',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Conheça as novas funcionalidades do portal do cliente.
      </Text>
    ),
    image: OnboardingImage1,
    thumb: Thumb2,
    video: TourVideo2,
    isModal: true,
  },
  step3: {
    title: 'Personalização geral',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Personalize a página inicial do portal com a cor que mais gera
        identificação com sua marca e organize os principais índices de maneira
        simples e prática.
        <br />
        <br />
        Aqui você também pode organizar a posição dos quadros e gráficos,
        arrastando e soltando os quadros em toda tela do dashboard.
      </Text>
    ),
    image: AddsWidgetAndColor,
  },
  step4: {
    title: 'Configurações dos quadros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Ajuste o tamanho da exibição dos quadros ou remova o quadro do
        dashboard.
      </Text>
    ),
    image: ChangeSizeAndOrder,
  },
  step5: {
    title: 'Marca e título do dashboard',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Insira sua marca no topo do nosso dashboard, para gerar mais conexão com
        seu negócio.
      </Text>
    ),
    gif: AddsNameAndImage,
    last: true,
  },
};
// Textos para o onboarding do Faq
export const faqTexts = {
  step1: {
    title: 'Boas-vindas a área de Central de Dúvidas do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá esclarecer possíveis dúvidas e encontrar
        informações úteis sobre o Portal do Cliente e outros serviços.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: HelpImage,
    first: true,
    isModal: true,
    bgColor: 'blue.700',
  },
  step2: {
    title: 'Busca',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Na barra de busca você pode digitar sua dúvida e encontrar possíveis
        soluções para o que deseja.
      </Text>
    ),
  },

  step3: {
    title: 'Principais dúvidas',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui temos os acessos rápidos para as principais dúvidas de nossos
        clientes. Você pode encontrar informações úteis como por exemplo os
        <strong>Telefones Importantes</strong> do Grupo Vamos.
        <br />
        <br />
        Se desejar ver mais informações sobre uma determinada dúvida, basta
        clicar em cima dela.
      </Text>
    ),
    last: true,
  },
};

// Textos para o onboarding do financeiro
export const finanTexts = {
  step1: {
    title: 'Boas-vindas a área de Faturamento do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá acompanhar os status de seus faturamentos
        mensais bem como aprovar ou contestar medições.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Money,
    first: true,
    isModal: true,
    bgColor: 'yellow.400',
  },

  step2: {
    title: 'Filtros de busca',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui nos filtros você pode selecionar o período que deseja visualizar
        bem como o status do faturamento e a filial.
      </Text>
    ),
    image: FilterOnboarding,
    isModal: true,
  },

  step3: {
    title: 'Faturamentos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa tabela aparecerão os seus faturamentos ordenados por mês. Em cada
        faturamento você conseguirá ver o{' '}
        <strong>valor do faturamento, período e status</strong>.
      </Text>
    ),
    isModal: true,
  },

  step4: {
    title: 'Faturamentos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando no botão de <strong>ver detalhes</strong> você irá para a tela
        com o detalhamento do seu faturamento. Também é nessa tela que você irá{' '}
        <strong>Aprovar ou Contestar</strong> suas medições.
      </Text>
    ),
    image: FinOnboarding,
    isModal: true,
    last: true,
  },

  step5: {
    title: 'Medição',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nesse primeiro conjunto de informações, você conseguirá ver o
        detalhamento da medição e o valor total dela. Também conseguirá baixar
        em excel se desejar e entrar em contato com a equipe do Grupo Vamos.
      </Text>
    ),
    first: true,
  },
  step6: {
    title: 'Validação',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa seção é onde você deverá Aprovar e Contestar seus faturamentos
        mensais.
      </Text>
    ),
  },
  step7: {
    title: 'Fatura e boleto',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Por fim, nessas últimas seções, você poderá visualizar e baixar a fatura
        e boleto referente a esse faturamento.
      </Text>
    ),
    last: true,
  },
};

// Textos para o onboarding do agendamento
export const scheduleTexts = {
  step1: {
    title: 'Boas-vindas a área de Agendamento do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá realizar todos os agendamentos para seus
        veículos, para os mais diversos motivos.
        <br />
        <br />
        Clique em Iniciar e aprenda como funciona a funcionalidade de
        agendamento!
      </Text>
    ),
    image: Schedule,
    isModal: true,
    bgColor: 'green.700',
    first: true,
  },
  step2: {
    title: 'Realizando um Agendamento',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Para realizar um <strong>Agendamento</strong> único ou um{' '}
        <strong>Agendamento em lote</strong>, clique no botão{' '}
        <strong>Novo Agendamento</strong>.
      </Text>
    ),
    last: true,
  },
  step3: {
    title: 'Realizando um Agendamento único',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Essa é a primeira etapa para realizar um agendamento único. Nesses
        campos você precisa digitar a <strong>Placa</strong> e a{' '}
        <strong>Quilometragem</strong> do veículo para realizar um agendamento
        único (apenas um veículo).
      </Text>
    ),
    first: true,
  },

  step4: {
    title: 'Agendamento em lote',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Caso você deseje abrir um agendamento para mais de uma placa, basta
        clicar no botão Realizar agendamento em lote.
      </Text>
    ),
    last: true,
  },

  step5: {
    title: 'Selecione o Serviço',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Agora na segunda etapa, Serviços, você precisa selecionar o serviço que
        deseja para o agendamento. Selecione o que melhor se enquadrar para sua
        necessidade.
      </Text>
    ),
    first: true,
  },
  step6: {
    title: 'Adicione detalhes da solicitação',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui você pode adicionar mais detalhes da sua solicitação. Escreva nesse
        campo tudo que achar necessário para realizarmos seu atendimento
        posteriormente.
      </Text>
    ),
  },

  step7: {
    title: 'Adicionar mais serviços',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Caso necessário, você pode adicionar mais de um serviço para o mesmo
        veículo. Pra isso basta clicar no botão{' '}
        <strong>Adicionar mais serviços</strong> e preencher os dados
        solicitados.
      </Text>
    ),
  },
  step8: {
    title: 'Observações e Anexos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui você pode escrever todas as demais informações que achar necessário
        e também adicionar imagens, vídeos ou documentos que forem úteis para a
        realização do seu atendimento.
        <br />
        <br />
        Quando estiver tudo pronto, basta clicar em <strong>
          Continuar
        </strong>{' '}
        para ir para a próxima etapa e selecionar os horários
      </Text>
    ),
    first: true,
    last: true,
  },
  step9: {
    title: 'Agenda e demais dados',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa última etapa você precisará selecionar nos campos a data e horário
        desejados para o seu agendamento e preencher os demais campos com as
        informações solicitadas.
        <br />
        <br />
        Quando estiver tudo preenchido, basta clicar no botão{' '}
        <strong>Finalizar</strong> para concluir o seu agendamento.
      </Text>
    ),
  },

  step10: {
    title: 'Baixar planilha modelo',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Para realizar um agendamento em lote, é necessário que você baixe uma
        planilha modelo que disponibilizamos e preencha com as placas e os
        demais dados dos veículos que você deseja agendar. Para baixar a
        planilha basta clicar no botão <strong>Baixar planilha modelo</strong>.
      </Text>
    ),
    first: true,
  },

  step11: {
    title: 'Upload da planilha modelo',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Após ter preenchido a planilha com todas as placas e os dados
        solicitados, clique no botão <strong>Importar</strong> para fazer o
        upload dela e em seguida clique no botão <strong>Continuar</strong>.
      </Text>
    ),
    last: true,
  },
  step12: {
    title: 'Agendamento em lote - Erro nos dados',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Se algum dado preenchido na planilha estiver incorreto, o Portal vai te
        retornar os erros e você deverá corrigi-los na sua planilha modelo e
        atualizá-la clicando no botão <strong>Atualizar planilha</strong>.
      </Text>
    ),
    last: true,
    first: true,
  },

  step13: {
    title: 'Fotos Hodômetro e Placa',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa última etapa você precisará adicionar as fotos do Hodômetro e da
        Placa do veículo.
        <br />
        <br />
        Quando estiver tudo pronto, basta clicar em <strong>Continuar </strong>
        para ir para a próxima etapa e selecionar os horários.
      </Text>
    ),
    isModal: true,
    last: true,
    first: true,
  },
};

// Textos para o onboarding do veículos
export const vehicleTexts = {
  step1: {
    title: 'Boas-vindas a área de Veículos/CRLVs do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar todos os veículos em sua frota, bem
        como solicitar ou baixar os documentos CRLVs deles.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Vehicle,
    isModal: true,
    first: true,
    bgColor: 'salmon.100',
  },
  step2: {
    title: 'Busca de veículos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui na barra de busca você pode buscar o veículo desejado, basta
        digitar o número da sua placa, renavam ou chassi.
      </Text>
    ),
  },
  step3: {
    title: 'Veículos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui aparecerão as informações resumidas sobre os veículos.
      </Text>
    ),
  },
  step4: {
    title: 'Dados do veículo e Download CRLV',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando no botão <strong>Dados do veículo completo</strong> você verá
        mais detalhes sobre o seu veículo. E clicando no botão{' '}
        <strong>Download CRLV</strong> você irá baixar o documento mais
        atualizado disponível do veículo.
      </Text>
    ),
    show: true,
  },
  step5: {
    title: 'Download todos os CRLVs',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando no botão <strong>Download todos os CRLVs</strong> você irá
        fazer o download de todos os documentos CRLVs disponíveis da sua base de
        veículos.
      </Text>
    ),
    show: true,
  },
};

// Textos para o onboarding do multas
export const finesTexts = {
  step1: {
    title: 'Boas-vindas a área de Multas do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar o histórico e status das multas dos
        veículos de sua frota bem como baixar as guias para pagamento delas.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Fines,
    first: true,
    isModal: true,
    bgColor: 'salmon.100',
  },
  step2: {
    title: 'Busca de veículos',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui na barra de busca você pode buscar o veículo desejado, basta
        digitar o número da sua placa, renavam ou chassi.
      </Text>
    ),
    show: true,
  },

  step3: {
    title: 'Detalhes da infração',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui você conseguirá visualizar todos os detalhes referente a infração,
        basta clicar em <br />
        <strong> Ver detalhes</strong>.
      </Text>
    ),
    last: true,
    isModal: true,
  },
  step4: {
    title: 'Indicação do condutor - dados do veículo',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa primeira etapa, você precisará apenas conferir se os dados do
        veículo estão corretos e quando finalizar a conferência, basta clicar em
        <strong> Continuar</strong>.
      </Text>
    ),
    first: true,
  },
  step5: {
    title: 'Indicação do condutor - dados do condutor',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nesta etapa você precisará preencher todos os campos com os dados do
        condutor. <br />
        Quando terminar de preencher clique em
        <strong> Finalizar</strong>.
      </Text>
    ),
    last: true,
  },
};

//Textos para o onboarding Logs
export const logsTexts = {
  step1: {
    title: 'Boas-vindas a área de Logs de Ações do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar o histórico das ações realizadas
        pelos usuários no Portal do Cliente.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Logs,
    first: true,
    isModal: true,
    bgColor: 'green.700',
  },
  step2: {
    title: 'Busca de usuários',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui na barra de busca você pode buscar o nome do usuário desejado e
        logo em seguida serão retornados todas as ações que esse usuário fez
        dentro do Portal do Cliente.
      </Text>
    ),
  },
  step3: {
    title: 'Filtros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando no botão Filtrar você poderá selecionar alguns filtros para
        facilitar a sua busca, tais como <strong>Seção</strong> acessada,{' '}
        <strong>Ação</strong> e <strong>Status</strong>.
      </Text>
    ),
  },
  step4: {
    title: 'Detalhes',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Você poderá ver mais detalhes da ação que foi realizada clicando no
        botão <strong>Ver detalhes</strong>.
      </Text>
    ),
    last: true,
    isModal: true,
  },
};

//Textos para onboarding modelos
export const modelsTexts = {
  step1: {
    title: 'Boas-vindas a área de Relatórios de Modelos do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar alguns dados compilados sobre sua
        frota
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },
  step2: {
    title: 'Filtros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando no botão de <strong>Filtros</strong> você poderá selecionar a
        filial que deseja visualizar os dados.
      </Text>
    ),
  },
  step3: {
    title: 'Distribuição da frota por modelo',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa seção é onde estará compilado os dados referente a distribuição da
        sua frota segmentado por modelos de veículos.
        <br />
        Na seção abaixo você poderá visualizar os dados dos veículos de toda a
        frota de veículos da sua base.
      </Text>
    ),
  },
  step4: {
    title: 'Baixar planilha',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Faça o download de uma planilha com todos os veículos da sua base
        clicando no botão <strong>Baixar planilha</strong>.
      </Text>
    ),
    last: true,
  },
};

//Textos para onboarding distribuição da frota
export const fleetDistTexts = {
  step1: {
    title:
      'Boas-vindas a área de Relatórios de Distribuição de Frota do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar alguns dados compilados sobre sua
        frota.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },
  step2: {
    title: 'Filtros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Aqui nos <strong>filtros</strong> você pode selecionar o tipo de dado
        que deseja visualizar.
      </Text>
    ),
  },
  step3: {
    title: 'Regiões do Brasil',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nesse quadro é possível visualizar com mais detalhes em quais regiões do
        Brasil os veículos da sua frota estão distribuídos.
      </Text>
    ),
  },
  step4: {
    title: 'Baixar planilha',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Faça o download de uma planilha com todos os veículos da sua base
        clicando no botão <strong>Baixar planilha</strong>.
      </Text>
    ),
    last: true,
  },
};

// Textos onboarding entrada e saída
export const entraceExitTexts = {
  step1: {
    title:
      'Boas-vindas a área de Relatórios de Manutenção - Entrada e Saída do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar alguns dados compilados sobre as
        manutenções da sua frota
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },

  step2: {
    title: 'Gráfico de Entrada e Saída de Manutenções',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        No gráfico é possível visualizar as quantidades de entradas e saídas de
        manutenções por meses, bem como ter uma visão macro do ano inteiro.
      </Text>
    ),
  },

  step3: {
    title: 'Filtros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nos filtros é possível selecionar o período que deseja estar
        visualizando os dados bem como se deseja visualizar por{' '}
        <strong>Dia</strong> ou por <strong>Mês</strong>.
      </Text>
    ),
  },

  step4: {
    title: 'Baixar planilha',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Faça o download de uma planilha com todos os veículos da sua base
        clicando no botão <strong>Baixar planilha</strong>.
      </Text>
    ),
    last: true,
  },
};

// Textos onboarding serviços
export const servicesTexts = {
  step1: {
    title:
      'Boas-vindas a área de Relatórios de Manutenção - Serviços do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar alguns dados compilados sobre as
        manutenções da sua frota.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },

  step2: {
    title: 'Gráfico de Serviços',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa seção você poderá visualizar um gráfico com o comparativo dos
        principais serviços de manutenções realizados
      </Text>
    ),
  },

  step3: {
    title: 'Filtros',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nos filtros é possível selecionar o período que deseja estar
        visualizando os dados bem como se deseja visualizar por dia o por mês e
        ainda poderá selecionar o tipo de serviço.
      </Text>
    ),
  },

  step4: {
    title: 'Atendimentos Realizados',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Nessa seção você poderá visualizar os dados relacionados a quantidade de
        serviços realizados segmentados por tipos de serviços.
      </Text>
    ),
  },
  step5: {
    title: 'Baixar planilha',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Faça o download de uma planilha com todos os veículos da sua base
        clicando no botão <strong>Baixar planilha</strong>.
      </Text>
    ),
  },
  step6: {
    title: 'Ações',
    subtitle: (
      <Text fontSize="xs" color="text.400">
        Clicando nos ícones ao lado você poderá visualizar mais informações e
        detalhes dos serviços de manutenção que foram realizados no veículo.
      </Text>
    ),
    last: true,
  },
};

export const documentTexts = {
  step1: {
    title: 'Boas-vindas a área de Gestão de Contratos do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá visualizar os contratos ativos de sua empresa e
        todos os demais detalhes referente a eles.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como navegar!
      </Text>
    ),
    image: Documents,
    first: true,
    isModal: true,
    bgColor: 'purple.100',
  },
  step2: {
    title: 'Contratos',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Seus contratos ficarão todos nessa página, e cada um deles estará
        identificado com o <strong>Número do contrato</strong>. Aqui você também
        encontra outros detalhes sobre ele, como o seu{' '}
        <strong>Plano contratado, Status e Prazo contratual</strong>.
      </Text>
    ),
  },
  step3: {
    title: 'Mais detalhes',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Você pode encontrar mais informações relacionadas aos seus contratos
        clicando no botão <strong>Ver mais</strong>.
      </Text>
    ),
    last: true,
  },
  step4: {
    title: 'Informações do contrato',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Na aba <strong>Aditivos</strong> é onde estará todos os detalhes
        referentes ao seu contrato.
      </Text>
    ),
  },
  step5: {
    title: 'Documentos do contrato',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Na aba <strong>Documentos</strong> você poderá visualizar e baixar todos
        os documentos relacionados ao seu contrato, tais com Termos aditivos,
        Relação de reajustes entre outros.
      </Text>
    ),
    last: true,
  },
};

export const preventiveTexts = {
  step1: {
    title:
      'Boas-vindas a área de Relatórios de Manutenções Preventivas do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá acompanhar os dados relacionados às manutenções
        preventivas da sua frota de veículos.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como funciona a
        funcionalidade de preventivas!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },
  step2: {
    title: 'Dados das preventivas',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Acompanhe os dados gerais das manutenções preventivas dos seus veículos,
        como preventivas em dia, a vencer e vencidas.
      </Text>
    ),
  },
  step3: {
    title: 'Preventivas vencidas',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Realize o agendamento das manutenções preventivas vencidas para seus
        veículos, basta clicar no botão <strong>Agendar</strong>.
      </Text>
    ),
  },
  step4: {
    title: 'Importação de dados',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Faça a importação dos dados a serem exibidos nesse dashboard clicando no
        botão <strong>Importar</strong>.
      </Text>
    ),
  },
  last: true,
};

export const availabilityTexts = {
  step1: {
    title:
      'Boas-vindas a área de Relatórios de Disponibilidade do Portal do Cliente',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Aqui é onde você poderá acompanhar os dados gerias relacionados a sua
        frota de veículos.
        <br />
        <br />
        Clique em <strong>Iniciar</strong> e aprenda como funciona a
        funcionalidade de disponibilidade!
      </Text>
    ),
    image: Reports,
    first: true,
    isModal: true,
    bgColor: 'salmon.200',
  },

  step2: {
    title: 'Dados gerais de disponibilidade da frota',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Acompanhe os dados gerais da disponibilidade da sua frota de veículos,
        como frota ativa, frota disponível e frota efetiva.
      </Text>
    ),
  },
  step3: {
    title: 'Importação de dados',
    subtitle: (
      <Text textAlign="center" fontSize="xs" color="text.400">
        Faça a importação dos dados a serem exibidos nesse dashboard clicando no
        botão <strong>Importar</strong>.
      </Text>
    ),
    last: true,
  },
};
