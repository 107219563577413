import React from 'react';
import { HiOutlineClock } from 'react-icons/hi';
import { MdAttachMoney } from 'react-icons/md';
import { Flex, Box, Text, Icon, FlexProps, Skeleton } from '@chakra-ui/react';
import { CardSkele } from 'components/Skeletons/Card';
import { Card } from './Card';

interface FatCardProps extends FlexProps {
  title: string;
  data?: number | string;
  isTime?: boolean;
  isLoading: boolean;
}

export const FatCard: React.FC<FatCardProps> = ({
  title,
  data,
  isTime,
  isLoading,
  ...props
}) => {
  if (isLoading)
    return (
      <CardSkele
        flexDir="column"
        justifyContent="center"
        minH="104px"
        maxH="104px"
        minW={props.w}
        display={props.display}
        grow={1}
      >
        <Flex
          px={4}
          py="12px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Skeleton w={title.length * 2} maxW="200px" h="20px" />

            <Skeleton w="120px" h="32px" mt="18px" />
          </Box>

          <Skeleton ml="12px" borderRadius="8px" w="52px" h="60px" />
        </Flex>
      </CardSkele>
    );

  return (
    <Card
      minH="104px"
      maxH="104px"
      minW={props.w}
      display={props.display}
      grow={1}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text lineHeight="20px" color="text.600">
            {title}
          </Text>

          <Text
            mt="18px"
            lineHeight="20px"
            fontSize="32px"
            fontWeight={600}
            color="text.100"
          >
            {data}
          </Text>
        </Box>

        <Icon
          ml="12px"
          borderRadius="8px"
          w="52px"
          h="60px"
          px="6px"
          py="12px"
          bg={isTime ? 'yellow.400' : 'green.800'}
          as={isTime ? HiOutlineClock : MdAttachMoney}
          color={isTime ? 'orange.200' : 'green.100'}
        />
      </Flex>
    </Card>
  );
};
