import React, { useState, forwardRef, useMemo } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import { TbEye, TbEyeOff } from 'react-icons/tb';
import InputMask from 'react-input-mask';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Icon,
  Text,
  VStack,
  Spinner,
  VisuallyHidden,
  Center,
  Button,
  Image,
} from '@chakra-ui/react';
import { SendSVG } from 'assets';
import Tag from 'components/Tag';

interface SimpleInputProps extends InputProps {
  label?: string;
  error?: string;
  isLoading?: boolean;
  labelColor?: string;
  handleFill?: (value?: string) => void;
  tagType?: string | null;
  clearSearch?: () => void;
  setSent?: () => void;
  onSearch?: () => void;
}

const SimpleInput = forwardRef<HTMLInputElement | null, SimpleInputProps>(
  (props, ref) => {
    const {
      variant,
      type,
      isDisabled,
      label,
      error,
      labelColor,
      isLoading,
      id,
      handleFill,
      tagType,
      clearSearch,
      setSent,
      onSearch,
    } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const getInputType = () => {
      if (showPassword) {
        return 'text';
      }

      return type;
    };

    const getIconColor = () => {
      if (variant === 'dark') {
        if (isDisabled) return 'grey.600';

        return 'grey.500';
      } else {
        if (isDisabled) return 'grey.500';

        return 'grey.500';
      }
    };

    const mask = useMemo(() => {
      switch (type) {
        case 'cnpj':
          return '99.999.999/9999-99';
        case 'cpf':
          return '999.999.999-99';
        case 'year':
          return '9999';
        case 'phone':
          return '(99) 9 9999-9999';

        case 'distance':
          return '999.999.999';
        case 'plate':
          return 'aaa9*999';
        default:
          return undefined;
      }
    }, [type]);

    return (
      <VStack id="aa" spacing="4px" w="100%" {...props}>
        {label && (
          <Text
            aria-hidden="true"
            fontSize="sm"
            fontWeight={400}
            color={labelColor || 'grey.500'}
            alignSelf="flex-start"
          >
            {label}
          </Text>
        )}
        <InputGroup variant={variant}>
          {mask ? (
            <Input
              mask={mask}
              as={InputMask}
              alwaysShowMask={false}
              maskPlaceholder={null}
              ref={ref}
              mb={0}
              w="100%"
              type={getInputType()}
              data-cy={id}
              onInput={e =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                e.target.value?.length > 12 && handleFill(e.target.value)
              }
              onChange={e =>
                e.target.value.length <= 12 && handleFill?.(e.target.value)
              }
              {...props}
            />
          ) : (
            <Input
              data-cy={id}
              ref={ref}
              mb={0}
              w="100%"
              {...props}
              type={getInputType()}
            />
          )}
          {isLoading ? (
            <InputRightElement
              mr="18px"
              onClick={() => setShowPassword(showPassword => !showPassword)}
            >
              <Spinner size="sm" color={labelColor || 'grey.500'} />
            </InputRightElement>
          ) : type === 'password' ? (
            <InputRightElement
              mr="18px"
              onClick={() => setShowPassword(showPassword => !showPassword)}
            >
              <Icon
                aria-label="Mostrar senha"
                as={showPassword ? TbEyeOff : TbEye}
                h={6}
                w={6}
                color={getIconColor()}
                alignSelf="center"
              />
            </InputRightElement>
          ) : type === 'sent' ? (
            <InputRightElement onClick={setSent} pr="8px">
              <Center
                cursor="pointer"
                mb="10px"
                as={type === 'search' ? Button : Center}
              >
                <Image
                  aria-label="Pesquisar"
                  src={SendSVG}
                  alignSelf="center"
                />
              </Center>
            </InputRightElement>
          ) : type === 'search' || type === 'search-ghost' ? (
            <InputRightElement mt="2px" pr="8px">
              <Center
                h="36px"
                w="36px"
                onClick={() => onSearch?.()}
                as={type === 'search' ? Button : Center}
                borderRadius="4px"
              >
                <Icon
                  color={type === 'search' ? 'white' : 'black'}
                  aria-label="Pesquisar"
                  as={HiOutlineSearch}
                  h="20px"
                  w="20px"
                  alignSelf="center"
                />
              </Center>
            </InputRightElement>
          ) : tagType ? (
            <InputRightElement pr="40px">
              <Tag label={tagType} />
            </InputRightElement>
          ) : clearSearch ? (
            <InputRightElement pr="40px">
              <CloseIcon
                p="4px"
                mr="-40px"
                bg="gray.200"
                borderRadius="full"
                w="16px"
                h="16px"
                color="black"
                onClick={() => clearSearch()}
              />
            </InputRightElement>
          ) : (
            <></>
          )}
        </InputGroup>
        {error && (
          <>
            <VisuallyHidden>
              <Text>Erro, {error}</Text>
            </VisuallyHidden>
            <Text
              aria-hidden="true"
              alignSelf="start"
              pl="8px"
              color="red.200"
              fontSize="sm"
            >
              {error}
            </Text>
          </>
        )}
      </VStack>
    );
  },
);

SimpleInput.displayName = 'Input';

export default SimpleInput;
