import React from 'react';
import { MdOutlineCampaign } from 'react-icons/md';
import {
  Icon,
  Flex,
  DrawerContent,
  DrawerHeader,
  Drawer,
  DrawerBody,
  VStack,
  DrawerCloseButton,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { CommuniqueItem } from 'components/CommuniqueItem';

export const CommuniqueCentral: React.FC = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <Flex cursor="pointer" onClick={onOpen}>
        <Icon as={MdOutlineCampaign} color="white" w="24px" h="24px" />
      </Flex>

      <Drawer size="sm" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent boxShadow="none" bg="transparent">
          <DrawerCloseButton />
          <DrawerHeader
            py="18px"
            bg="mutable.100"
            fontSize="16px"
            ml="74px"
            w="370px"
          >
            O que há de novo no Portal?
          </DrawerHeader>

          <DrawerBody
            position="relative"
            overflowY="visible"
            ml="74px"
            w="370px"
            bg="white"
          >
            <VStack gap="16px">
              <CommuniqueItem />
              <CommuniqueItem />
              <CommuniqueItem />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
