import React, { useEffect, useMemo } from 'react';
import { HiOutlineClock } from 'react-icons/hi';
import { IoIosHourglass, IoMdCheckmark } from 'react-icons/io';
import {
  Flex,
  Text,
  Box,
  Center,
  Icon,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  FlexProps,
} from '@chakra-ui/react';
import { useLazyGetPreventiveCardQuery } from '@spartalabs/pdc-core';
import { ForkLift } from 'components/CustomIcons/ForkLift';
import { RepairIcon } from 'components/CustomIcons/RepairIcon';
import { BiSkeleton } from 'components/Skeletons/BI';
import { Card } from '../Card';
import { ErrorCard } from '../ErrorCard';

interface PreventCardProps extends FlexProps {
  isSolo?: boolean;
  costCenters: string;
  cardOptions?: CardOptions;
  cardRef?: CardRef;
}

export const PreventCards: React.FC<PreventCardProps> = ({
  isSolo = false,
  costCenters = '',
  cardOptions,
  cardRef,
  ...rest
}) => {
  const [fetch, { data, isLoading, isFetching, isError }] =
    useLazyGetPreventiveCardQuery();

  const cardSoloInfo = useMemo(
    () => [
      {
        text: 'Em dia',
        width: {
          base: '100%',
          md: '150px',
        },
        color: '#4AD991',
        bg: '#D9F7E7',
        icon: IoMdCheckmark,
        isPercent: false,
        isMultiple: false,
        value: data?.onTime,
      },
      {
        text: 'A vencer',
        width: {
          base: '46%',
          md: '150px',
        },
        color: '#FFBB38',
        bg: '#FFF5D9',
        icon: IoIosHourglass,
        isPercent: false,
        isMultiple: false,
        value: data?.dueSoon,
      },
      {
        text: 'Vencidas',
        width: {
          base: '46%',
          md: isSolo ? '50%' : '150px',
          xl: '150px',
        },
        color: '#FF3838',
        bg: '#FFD7D7',
        icon: HiOutlineClock,
        isPercent: false,
        isMultiple: false,
        value: data?.overdue,
      },
    ],
    [data],
  );

  const cardInfo = isSolo
    ? [
        {
          text: 'Preventivas',
          width: {
            base: '100%',
            md: '180px',
          },
          isPercent: true,
          isMultiple: false,
          icon: null,
          value: '',
        },
        ...cardSoloInfo,
        {
          width: {
            base: '47%',
            xl: '440px',
          },
          isMultiple: true,
          isPercent: false,
          color: '#396AFF',
          bg: '#E7EDFF',
        },
      ]
    : cardSoloInfo;

  useEffect(() => {
    if (costCenters) fetch(costCenters);
  }, [costCenters]);

  return (
    <Flex
      ref={cardRef}
      _hover={
        cardOptions?.widget.size === '50%'
          ? {
              borderColor: 'ocean.400',
            }
          : undefined
      }
      flexWrap="wrap"
      flex={1}
      w="100%"
      gap="20px"
      {...cardOptions?.dragHandler?.dragHandleProps}
      {...rest}
    >
      {isError ? (
        <ErrorCard refetch={() => fetch(costCenters)} minH="104px" w="1000%" />
      ) : (
        cardInfo.map(card =>
          isFetching || isLoading || costCenters?.length < 1 ? (
            <BiSkeleton key={card.text} />
          ) : (
            <Card
              flex={1}
              key={card.text}
              minH="104px"
              h={isSolo ? '104px' : '142px'}
              w={card.width}
              minW={card.width}
            >
              {card.isPercent && isSolo ? (
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text w="140px" fontSize="16px" textColor="text.100">
                    {card.text}
                  </Text>

                  <CircularProgress
                    color="green.100"
                    thickness="4px"
                    size="72px"
                    value={Number(data?.preventives) || 0}
                  >
                    <CircularProgressLabel textColor="text.100">
                      {data?.preventives}%
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              ) : card.isMultiple && isSolo ? (
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box w="100%">
                    <Text minW="156px" fontSize="16px" textColor="text.500">
                      Total em manutenção
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.totalInMaintenance}
                    </Text>
                  </Box>
                  <Divider
                    mt="20px"
                    orientation="vertical"
                    borderColor="#D3D3D4"
                    h="25px"
                    borderWidth="1px"
                  />
                  <Box pl="8px" w="40%">
                    <Text fontSize="16px" textColor="text.500">
                      Parado
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.stopped}
                    </Text>
                  </Box>
                  <Divider
                    mt="20px"
                    orientation="vertical"
                    borderColor="#D3D3D4"
                    h="25px"
                    borderWidth="1px"
                  />
                  <Box pl="8px" w="60%">
                    <Text fontSize="16px" textColor="text.500">
                      Rodando
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.running}
                    </Text>
                  </Box>
                  {card.color && (
                    <Center
                      display={{ base: 'none', sm: 'grid' }}
                      position="relative"
                      borderRadius="8px"
                      bg={card.bg}
                      w="56px"
                      h="56px"
                    >
                      <ForkLift color={card.color} />
                    </Center>
                  )}
                </Flex>
              ) : (
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    {!isSolo && (
                      <Text
                        fontSize="16px"
                        fontWeight={600}
                        textColor="text.500"
                      >
                        Preventivas
                      </Text>
                    )}
                    <Text fontSize="16px" textColor="text.500">
                      {card.text}
                    </Text>
                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {card.value}
                    </Text>
                  </Box>
                  {card.color && (
                    <Center
                      position="relative"
                      borderRadius="8px"
                      bg={card.bg}
                      w="56px"
                      h="56px"
                    >
                      {card.icon && (
                        <Icon
                          p="4px"
                          w="20px"
                          h="20px"
                          bg={card.color}
                          color="white"
                          borderRadius={20}
                          top="-8px"
                          right="-8px"
                          position="absolute"
                          as={card.icon}
                        />
                      )}

                      <RepairIcon color={card.color} />
                    </Center>
                  )}
                </Flex>
              )}
            </Card>
          ),
        )
      )}
    </Flex>
  );
};
