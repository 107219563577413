import React from 'react';

interface CommuniqueItemProps {
  isOutline: boolean;
}

export const HappyFace: React.FC<CommuniqueItemProps> = ({ isOutline }) => {
  return isOutline ? (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 28.6809C23.6749 28.6809 28.6806 23.6752 28.6806 17.5004C28.6806 11.3255 23.6749 6.31982 17.5 6.31982C11.3252 6.31982 6.31946 11.3255 6.31946 17.5004C6.31946 23.6752 11.3252 28.6809 17.5 28.6809Z"
        fill="#FFCF56"
      />
      <path
        d="M24.5948 20.2422C24.6006 20.9906 24.4568 21.7327 24.1718 22.4248C18.1003 23.8977 11.81 22.5901 10.8086 22.3616C10.537 21.6883 10.3999 20.9682 10.4052 20.2422H10.4586C10.4586 20.2422 17.6531 21.9873 24.5024 20.2762L24.5948 20.2422Z"
        fill="white"
      />
      <path
        d="M24.1719 22.4245C23.3018 24.5002 21.0851 25.9391 17.517 25.9391C13.915 25.9391 11.6691 24.4662 10.8087 22.3613C11.8101 22.5898 18.1003 23.8974 24.1719 22.4245Z"
        fill="#EA5A47"
      />
      <g filter="url(#filter0_d_8625_4813)">
        <path
          d="M17.5 29.6809C24.2271 29.6809 29.6806 24.2275 29.6806 17.5004C29.6806 10.7732 24.2271 5.31982 17.5 5.31982C10.7729 5.31982 5.31946 10.7732 5.31946 17.5004C5.31946 24.2275 10.7729 29.6809 17.5 29.6809Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M13.8874 17.6077C14.6928 17.6077 15.3457 16.4316 15.3457 14.9809C15.3457 13.5301 14.6928 12.354 13.8874 12.354C13.082 12.354 12.4291 13.5301 12.4291 14.9809C12.4291 16.4316 13.082 17.6077 13.8874 17.6077Z"
        fill="black"
      />
      <path
        d="M21.1126 17.6097C21.918 17.6097 22.571 16.4336 22.571 14.9828C22.571 13.532 21.918 12.356 21.1126 12.356C20.3072 12.356 19.6543 13.532 19.6543 14.9828C19.6543 16.4336 20.3072 17.6097 21.1126 17.6097Z"
        fill="black"
      />
      <path
        d="M24.5948 20.2422C24.6006 20.9906 24.4568 21.7327 24.1718 22.4248C18.1003 23.8977 11.81 22.5901 10.8086 22.3616C10.537 21.6883 10.3999 20.9682 10.4052 20.2422H10.4586C10.4586 20.2422 17.6531 21.9873 24.5024 20.2762L24.5948 20.2422Z"
        stroke="black"
        strokeWidth="0.80555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1719 22.4245C23.3018 24.5002 21.0851 25.9391 17.517 25.9391C13.915 25.9391 11.6691 24.4662 10.8087 22.3613C11.8101 22.5898 18.1003 23.8974 24.1719 22.4245Z"
        stroke="black"
        strokeWidth="0.80555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_8625_4813"
          x="2.31946"
          y="3.31982"
          width="30.3611"
          height="30.3613"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8625_4813"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8625_4813"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8625_4869)">
        <path
          d="M14.4995 23.764C19.6158 23.764 23.7634 19.6164 23.7634 14.5002C23.7634 9.38388 19.6158 5.23633 14.4995 5.23633C9.38327 5.23633 5.23572 9.38388 5.23572 14.5002C5.23572 19.6164 9.38327 23.764 14.4995 23.764Z"
          fill="#CFCFCF"
        />
        <path
          d="M20.378 16.772C20.3828 17.3921 20.2637 18.007 20.0276 18.5804C14.9969 19.8008 9.78499 18.7174 8.95527 18.5281C8.73021 17.9702 8.61664 17.3736 8.62097 16.772H8.66527C8.66527 16.772 14.6263 18.2179 20.3014 16.8002L20.378 16.772Z"
          fill="white"
        />
        <path
          d="M20.0276 18.5802C19.3066 20.3 17.47 21.4923 14.5136 21.4923C11.5291 21.4923 9.66823 20.2718 8.95532 18.5278C9.78504 18.7171 14.9969 19.8006 20.0276 18.5802Z"
          fill="#505050"
        />
        <g filter="url(#filter0_d_8625_4869)">
          <path
            d="M14.4995 24.764C20.1681 24.764 24.7634 20.1687 24.7634 14.5002C24.7634 8.8316 20.1681 4.23633 14.4995 4.23633C8.83099 4.23633 4.23572 8.8316 4.23572 14.5002C4.23572 20.1687 8.83099 24.764 14.4995 24.764Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            shapeRendering="crispEdges"
          />
        </g>
        <path
          d="M11.5063 14.5889C12.1736 14.5889 12.7146 13.6144 12.7146 12.4124C12.7146 11.2103 12.1736 10.2358 11.5063 10.2358C10.839 10.2358 10.298 11.2103 10.298 12.4124C10.298 13.6144 10.839 14.5889 11.5063 14.5889Z"
          fill="#505050"
        />
        <path
          d="M17.4927 14.5908C18.1601 14.5908 18.7011 13.6164 18.7011 12.4143C18.7011 11.2123 18.1601 10.2378 17.4927 10.2378C16.8254 10.2378 16.2844 11.2123 16.2844 12.4143C16.2844 13.6164 16.8254 14.5908 17.4927 14.5908Z"
          fill="#505050"
        />
        <path
          d="M20.378 16.772C20.3828 17.3921 20.2637 18.007 20.0276 18.5804C14.9969 19.8008 9.78499 18.7174 8.95527 18.5281C8.73021 17.9702 8.61664 17.3736 8.62097 16.772H8.66527C8.66527 16.772 14.6263 18.2179 20.3014 16.8002L20.378 16.772Z"
          stroke="#505050"
          strokeWidth="0.80555"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0276 18.5802C19.3066 20.3 17.47 21.4923 14.5136 21.4923C11.5291 21.4923 9.66823 20.2718 8.95532 18.5278C9.78504 18.7171 14.9969 19.8006 20.0276 18.5802Z"
          stroke="#505050"
          strokeWidth="0.80555"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8625_4869"
          x="1.23572"
          y="2.23633"
          width="26.5276"
          height="26.5278"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8625_4869"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8625_4869"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_8625_4869">
          <rect
            width="28.9998"
            height="28.9998"
            fill="white"
            transform="translate(-0.000366211)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
