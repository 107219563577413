import React from 'react';

interface CommuniqueItemProps {
  isOutline: boolean;
}

export const SadFace: React.FC<CommuniqueItemProps> = ({ isOutline }) => {
  return isOutline ? (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6.31982C11.335 6.31982 6.31946 11.3354 6.31946 17.5004C6.31946 23.6653 11.335 28.6809 17.5 28.6809C23.665 28.6809 28.6806 23.6653 28.6806 17.5004C28.6806 11.3354 23.665 6.31982 17.5 6.31982Z"
        fill="#FFCF56"
      />
      <path
        d="M17.5 29.6809C24.2271 29.6809 29.6806 24.2275 29.6806 17.5004C29.6806 10.7732 24.2271 5.31982 17.5 5.31982C10.7729 5.31982 5.31946 10.7732 5.31946 17.5004C5.31946 24.2275 10.7729 29.6809 17.5 29.6809Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5833 15.0701C14.5833 15.8755 13.9296 16.5285 13.125 16.5285C12.3203 16.5285 11.6666 15.8755 11.6666 15.0701C11.6666 14.2655 12.3203 13.6118 13.125 13.6118C13.9296 13.6118 14.5833 14.2655 14.5833 15.0701Z"
        fill="black"
      />
      <path
        d="M23.3333 15.0701C23.3333 15.8755 22.6796 16.5285 21.875 16.5285C21.0703 16.5285 20.4166 15.8755 20.4166 15.0701C20.4166 14.2655 21.0703 13.6118 21.875 13.6118C22.6796 13.6118 23.3333 14.2655 23.3333 15.0701Z"
        fill="black"
      />
      <path
        d="M13.6111 22.3621C14.3794 21.1189 15.9951 20.3521 17.7047 20.4158C19.2742 20.4743 20.6899 21.2243 21.3889 22.3621"
        stroke="black"
        strokeWidth="0.80555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4999 5.23633C9.39179 5.23633 5.23608 9.39204 5.23608 14.5002C5.23608 19.6082 9.39179 23.764 14.4999 23.764C19.608 23.764 23.7637 19.6082 23.7637 14.5002C23.7637 9.39204 19.608 5.23633 14.4999 5.23633Z"
        fill="#D0D0D0"
      />
      <path
        d="M14.4999 24.764C20.1685 24.764 24.7637 20.1687 24.7637 14.5002C24.7637 8.8316 20.1685 4.23633 14.4999 4.23633C8.83135 4.23633 4.23608 8.8316 4.23608 14.5002C4.23608 20.1687 8.83135 24.764 14.4999 24.764Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0833 12.4866C12.0833 13.154 11.5416 13.695 10.875 13.695C10.2082 13.695 9.66663 13.1539 9.66663 12.4866C9.66663 11.82 10.2082 11.2783 10.875 11.2783C11.5416 11.2783 12.0833 11.82 12.0833 12.4866Z"
        fill="#505050"
      />
      <path
        d="M19.3332 12.4866C19.3332 13.154 18.7915 13.695 18.1248 13.695C17.4581 13.695 16.9165 13.1539 16.9165 12.4866C16.9165 11.82 17.4581 11.2783 18.1248 11.2783C18.7915 11.2783 19.3332 11.82 19.3332 12.4866Z"
        fill="#505050"
      />
      <path
        d="M11.2777 18.5283C11.9143 17.4982 13.253 16.8628 14.6695 16.9157C15.97 16.9641 17.143 17.5856 17.7221 18.5283"
        stroke="#505050"
        strokeWidth="0.80555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
