import React, { useMemo } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { HiOutlineExclamation } from 'react-icons/hi';
import { FlexProps, Icon, Text, Button } from '@chakra-ui/react';
import { Card } from './Card';

interface ErrorCard extends FlexProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: any;
  cardRef?: DraggableProvided['innerRef'];
  dragHandler?: DraggableProvided;
  cardOptions?: CardOptions;
  openToken?: () => void;
  hasApprovers?: boolean;
  small?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

export const ErrorCard: React.FC<ErrorCard> = ({
  refetch,
  error,
  openToken,
  hasApprovers,
  small,
  ...rest
}) => {
  const isToken = useMemo(() => {
    if (error && error?.data?.Conteudo === 'Token inválido!') return true;

    return false;
  }, [error]);

  return (
    <Card {...rest} py="28px" align="center">
      {!small && (
        <Icon
          borderRadius="100px"
          padding="16px"
          bg="grey.200"
          w="80px"
          h="80px"
          as={HiOutlineExclamation}
          color="grey.600"
        />
      )}
      <Text
        fontWeight="medium"
        color="text.100"
        fontSize={small ? '16px' : '20px'}
      >
        {isToken
          ? hasApprovers
            ? 'Acesso inválido'
            : 'Sem e-mail cadastrado'
          : 'Ocorreu um erro'}
      </Text>

      {!small && (
        <Text textAlign="center" color="text.300" fontSize="16px">
          {isToken
            ? hasApprovers
              ? 'Para sua segurança, valide seu acesso pelo e-mail.'
              : 'Sem autorização para realizar validação de Faturamento via Portal do Cliente, contate o suporte pelo telefone 0800 025 4141.'
            : ' Recarregue e tente novamente'}
        </Text>
      )}
      <Button
        display={isToken && !hasApprovers ? 'none' : 'flex'}
        mt="12px"
        fontSize="14px"
        variant="outline"
        onClick={() => (isToken ? openToken?.() : refetch())}
      >
        {isToken ? 'Validar acesso' : 'Recarregar'}
      </Button>
    </Card>
  );
};
