import React from 'react';

interface CommuniqueItemProps {
  isOutline: boolean;
}

export const Face: React.FC<CommuniqueItemProps> = ({ isOutline }) => {
  return !isOutline ? (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4997 5.23633C9.39155 5.23633 5.23584 9.39204 5.23584 14.5002C5.23584 19.6082 9.39155 23.764 14.4997 23.764C19.6077 23.764 23.7635 19.6082 23.7635 14.5002C23.7635 9.39204 19.6077 5.23633 14.4997 5.23633Z"
        fill="#D0D0D0"
      />
      <path
        d="M14.4997 24.764C20.1682 24.764 24.7635 20.1687 24.7635 14.5002C24.7635 8.8316 20.1682 4.23633 14.4997 4.23633C8.83111 4.23633 4.23584 8.8316 4.23584 14.5002C4.23584 20.1687 8.83111 24.764 14.4997 24.764Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.8746 17.3198H18.1246"
        stroke="#505050"
        strokeWidth="0.80555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.083 12.4866C12.083 13.154 11.5414 13.695 10.8747 13.695C10.208 13.695 9.66638 13.1539 9.66638 12.4866C9.66638 11.82 10.208 11.2783 10.8747 11.2783C11.5414 11.2783 12.083 11.82 12.083 12.4866Z"
        fill="#505050"
      />
      <path
        d="M19.3329 12.4866C19.3329 13.154 18.7913 13.695 18.1246 13.695C17.4579 13.695 16.9163 13.1539 16.9163 12.4866C16.9163 11.82 17.4579 11.2783 18.1246 11.2783C18.7913 11.2783 19.3329 11.82 19.3329 12.4866Z"
        fill="#505050"
      />
    </svg>
  ) : (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6.31982C11.335 6.31982 6.31946 11.3354 6.31946 17.5004C6.31946 23.6653 11.335 28.6809 17.5 28.6809C23.665 28.6809 28.6806 23.6653 28.6806 17.5004C28.6806 11.3354 23.665 6.31982 17.5 6.31982Z"
        fill="#FFCF56"
      />
      <path
        d="M17.5 29.6809C24.2271 29.6809 29.6806 24.2275 29.6806 17.5004C29.6806 10.7732 24.2271 5.31982 17.5 5.31982C10.7729 5.31982 5.31946 10.7732 5.31946 17.5004C5.31946 24.2275 10.7729 29.6809 17.5 29.6809Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.125 20.9033H21.875"
        stroke="black"
        strokeWidth="0.80555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5833 15.0701C14.5833 15.8755 13.9296 16.5285 13.125 16.5285C12.3203 16.5285 11.6666 15.8755 11.6666 15.0701C11.6666 14.2655 12.3203 13.6118 13.125 13.6118C13.9296 13.6118 14.5833 14.2655 14.5833 15.0701Z"
        fill="black"
      />
      <path
        d="M23.3333 15.0701C23.3333 15.8755 22.6796 16.5285 21.875 16.5285C21.0703 16.5285 20.4166 15.8755 20.4166 15.0701C20.4166 14.2655 21.0703 13.6118 21.875 13.6118C22.6796 13.6118 23.3333 14.2655 23.3333 15.0701Z"
        fill="black"
      />
    </svg>
  );
};
