import React from 'react';
import { Flex, Text, Box, Skeleton } from '@chakra-ui/react';
import { CardSkele } from '../Card';

export const BiSkeleton: React.FC = () => {
  return (
    <CardSkele flex={1} minH="104px" minW="180px" h="104px">
      <Flex px="20px" alignItems="center" justifyContent="space-between">
        <Box>
          <Skeleton mt="20px" w="90px" h="12px" />
          <Text fontSize="16px" textColor="text.500"></Text>
          <Skeleton mt="8px" w="32px" h="40px" />
        </Box>

        <Skeleton mt="20px" w="56px" h="56px" />
      </Flex>
    </CardSkele>
  );
};
