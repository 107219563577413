import React, { useMemo } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
interface TagProps extends TextProps {
  label: string;
  size?: string;
}

const Tag: React.FC<TagProps> = ({ label, ...rest }) => {
  const statusColor = useMemo(() => {
    switch (label) {
      case 'Paga':
        return { text: 'green.300', bg: 'green.200' };

      case 'Novo':
      case 'Concluído':
      case 'Desconto Acréscimo Aprovado':
      case 'Faturado':
      case 'Sucesso':
      case 'Criação':
      case 'Aprovação Fatura':
      case 'Chassi':
      case 'Condutor indicado':
      case 'Novidade':
      case 'Em dia':
      case 'Sim':
        return { text: 'green.500', bg: 'green.400' };

      case 'Pendente Análise':
      case 'Aguardando Correção Contrato':
      case 'Aguardando Faturamento ':
      case 'Aguardando Envio Cliente':
      case 'Aguardando Aprovação Desconto':
      case 'Processando Faturamento':
      case 'Em análise':
      case 'Em andamento':
      case 'Em processo':
      case 'Aguardando Validação Cliente':
      case 'Criou conteúdo':
      case 'Contestação Fatura':
      case 'Alteração':
      case 'Condutor não indicado':
      case 'Evento':
      case 'A vencer':
      case 'Ag.aprovação':
        return { text: 'red.100', bg: 'yellow.200' };

      case 'Medição Contestada':
      case 'Erro ao Faturar':
      case 'Exclusão':
      case 'Cancelado':
      case 'Cancelamento':
      case 'Aviso':
      case 'Vencida':
      case 'Não':
      case 'CRLV não Encontrado':
        return { text: 'red.100', bg: 'red.300' };

      case 'Alterou conteúdo':
      case 'Download':
      case 'Estoque':
      case 'Placa':
      case 'Contrato':
      case 'Aprovado':
      case 'CRLV em Lote':
        return { text: 'tag.200', bg: 'tag.100' };
      case 'Marketing':
        return { text: 'purple.200', bg: 'purple.300' };
      case 'Atualizações':
        return { text: 'pink.100', bg: 'pink.200' };

      case 'Não iniciado':
        return { text: 'grey.800', bg: 'grey.300' };

      default:
        return { text: 'text.100', bg: 'grey.400' };
    }
  }, [label]);

  return (
    <Flex justifyContent="flex-start">
      <Text
        fontWeight={400}
        py={0.5}
        px={2}
        borderRadius={32}
        bg={statusColor.bg}
        border="1px solid"
        borderColor="blue.400"
        color={statusColor.text}
        textAlign="center"
        {...rest}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default Tag;
