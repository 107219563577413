import React, { useMemo, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { Box, CloseButton, Flex, BoxProps, Text } from '@chakra-ui/react';
import { SkipNavLink } from '@chakra-ui/skip-nav';
import { useAppSelector } from '@spartalabs/pdc-core';
import isotipoSvg from 'assets/svg/isotipo.svg';
import logoSvg from 'assets/svg/logo.svg';
import { isDev, isProd } from 'utils/functions';
import { NavBtn } from '../NavButtons';

const exampleContent = [
  {
    name: 'Drag n Drop',
    path: '/exemplos/dnd',
  },
  {
    name: 'Cards',
    path: '/exemplos/cards',
  },
  {
    name: 'Componentes',
    path: '/exemplos/componentes',
  },
  {
    name: 'Graficos',
    path: '/exemplos/graficos',
  },
  {
    name: 'Tabelas',
    path: '/exemplos/tabelas',
  },
];

const dropContent = {
  report: [
    {
      name: 'Modelos',
      path: '/relatorios/modelos',
    },
    {
      name: 'Distribuição da Frota',
      path: '/relatorios/distdefrotas',
    },
    { name: 'Disponibilidade', path: '/relatorios/disponibilidade' },
    {
      name: 'Manutenção',
      path: '/manutencao',
      insideDrops: [
        {
          name: 'Entrada e Saída',
          path: '/relatorios/manutencao/entradasaida',
        },
        { name: 'Preventivas', path: '/relatorios/manutencao/preventivas' },
        { name: 'Serviços', path: '/relatorios/manutencao/servicos' },
      ],
    },
  ],
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isCollapsed: boolean;
  sideBarName: string;
}

interface optionProps {
  fp: string[];
  sp: string[];
  tp: string[];
}
export const SidebarContent = ({
  onClose,
  isCollapsed,
  sideBarName,
  ...rest
}: SidebarProps) => {
  const permissions = useAppSelector(
    state => state.authSlicer.user.permissions,
  );

  const [option, setOption] = useState<optionProps>({
    fp: ['home'],
    sp: [],
    tp: [],
  });

  const vehiclesDrop = useMemo(() => {
    const drop = [];

    drop.push({
      name: 'Veículos/CRLV',
      path: 'gestao/veiculos',
    });

    if (permissions?.includes('fines'))
      drop.push({
        name: 'Infrações/Multas',
        path: 'gestao/multas',
      });

    return drop;
  }, [permissions]);

  return (
    <Box
      zIndex={10}
      bg="mutable.100"
      w={{ base: 'full', xl: isCollapsed ? '86px' : '248px' }}
      pos="fixed"
      h="full"
      px={isCollapsed ? '16px' : '12px'}
      pt="44px"
      transition=" width ease-in-out 0.3s"
      overflowY={isCollapsed ? 'visible' : 'scroll'}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      {...rest}
    >
      <Flex justifyContent="space-between" mb="34px" alignItems="center">
        <Image
          alt=""
          pl="8px"
          src={isCollapsed ? isotipoSvg : logoSvg}
          w="100px"
        />
        <CloseButton
          data-cy={`close-${sideBarName}`}
          display={{ base: 'flex', xl: 'none' }}
          onClick={onClose}
        />
      </Flex>
      <SkipNavLink>
        <Text textColor="text.100">Pular navegação</Text>
      </SkipNavLink>

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Início"
        path="/inicio"
      />
      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Relatórios"
        isDropable
        dropContent={dropContent.report}
        path="/relatorios"
      />
      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Agendamentos"
        path="/agendamentos"
      />

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Faturamento"
        path="/faturamento"
      />

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Vamos Controle"
        path="https://www.vamoscontrole.com.br/iweb2/loginvamoscontrole.aspx"
      />

      {!isProd() && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Acessos"
          path="/acessos"
        />
      )}

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Central de Dúvidas"
        path="/centraldeduvidas"
      />
      {!isProd() && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Gestão de contratos"
          path="/gestaodecontratos"
        />
      )}

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        isDropable
        dropContent={vehiclesDrop}
        name="Gestão de Veículos"
        path="/gestao"
      />

      {permissions?.includes('logs') && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Log de ações"
          path="/logs"
        />
      )}

      {isDev() && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Exemplos"
          isDropable
          dropContent={exampleContent}
          path="/examples"
        />
      )}
    </Box>
  );
};
