import React from 'react';

export const RepairIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0001 27.7494L31.6703 24.4196L30.7824 25.3076M22.6059 35.482L21.6069 34.483L22.4209 33.6691M22.4209 33.6691L14.9473 26.1955M22.4209 33.6691L30.7824 25.3076M14.9473 26.1955L21.3849 19.672M14.9473 26.1955L12.5794 28.5634M7.10375 23.5687L9.84159 21.2008M9.84159 21.2008L12.3205 23.6427C13.6277 24.1113 15.9832 24.2494 14.9473 21.0528L14.9158 21.0213M9.84159 21.2008L8.17669 19.1659C7.9991 16.9757 9.7306 16.6254 10.6185 16.7241M10.6185 16.7241C9.55301 14.4154 11.3585 13.6902 12.3944 13.6163M10.6185 16.7241L14.9158 21.0213M30.7824 25.3076L29.4875 23.5317C29.1052 21.9778 28.3553 18.5518 28.4145 17.279M28.3775 17.205C28.3405 16.8474 27.9706 15.7991 26.7866 14.4672L26.6386 14.3144M19.1281 15.2072C18.7827 15.9594 18.6027 17.5158 20.645 17.723C20.9656 17.6983 21.8511 17.4566 22.8278 16.6871C23.4938 16.5391 24.7665 16.6427 24.5298 18.241C23.9748 18.7713 22.8352 19.9133 22.7169 20.2389C22.5689 20.6458 22.1989 23.5317 24.0488 24.3086M19.1281 15.2072L21.5699 12.705M19.1281 15.2072L14.9843 10.8044M26.6386 14.3144L24.5298 12.1363C24.2214 11.828 23.3088 11.3964 22.1249 12.1363L21.5699 12.705M26.6386 14.3144L28.0815 12.9873C29.2408 13.0736 31.7961 12.9355 32.7433 11.6924C33.3969 11.1004 34.7634 9.52428 35.0001 7.95557C34.9385 7.07996 34.7782 5.24733 34.6302 4.92175L31.0044 8.43655L28.0076 8.14056L27.3416 5.25473L31.0044 1.33295C30.0794 1.14796 27.8522 0.970375 26.3426 1.73993C25.689 2.07291 24.2116 3.10885 23.5308 4.58877C23.3458 5.05741 23.0202 6.26847 23.1978 7.36361L19.942 10.8044M19.942 10.8044L21.5699 12.705M19.942 10.8044L17.5372 8.69553C16.4395 8.32555 14.3923 8.22936 14.9843 10.8044M14.9843 10.8044C13.5044 10.4221 10.9145 10.4492 12.3944 13.6163M12.3944 13.6163L16.9822 17.982M16.9822 17.982C17.3892 18.056 18.3881 17.982 19.1281 17.0941M16.9822 17.982C17.6148 19.1696 18.0873 21.4401 14.9158 21.0213M5.66083 26.1955L8.73166 27.0095L9.84159 30.2653L7.51073 32.6332L4.21792 31.8932L3.29297 28.5634L5.66083 26.1955Z"
        stroke={color}
        strokeWidth="1.33192"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.84356 23.6796C7.90228 23.3696 7.52861 23.0078 7.21459 22.9767C6.01897 22.858 4.70916 23.1798 3.62368 23.7121C2.36662 24.3286 1.34327 25.336 0.707065 26.5832C0.0708562 27.8304 -0.14393 29.2502 0.0949101 30.6298C0.33375 32.0094 1.01335 33.2743 2.0318 34.2351C3.05026 35.1958 4.3527 35.8005 5.74385 35.9585C7.135 36.1166 8.53989 35.8194 9.74789 35.1116C10.9559 34.4038 11.9019 33.3234 12.4441 32.0326C12.9123 30.9179 13.0576 29.6981 12.8694 28.5115C12.82 28.1998 12.507 28.014 12.2009 28.0906C11.8948 28.1673 11.7121 28.4776 11.7556 28.7901C11.8873 29.7358 11.763 30.7033 11.3905 31.5901C10.944 32.6531 10.165 33.5427 9.17017 34.1256C8.17538 34.7085 7.01845 34.9532 5.87283 34.8231C4.72721 34.693 3.65465 34.195 2.81594 33.4038C1.97723 32.6127 1.41758 31.5709 1.2209 30.4348C1.02421 29.2988 1.20109 28.1295 1.72501 27.1025C2.24893 26.0754 3.09167 25.2458 4.12686 24.7381C4.99038 24.3146 5.949 24.1341 6.90074 24.2104C7.21529 24.2357 7.78484 23.9897 7.84356 23.6796Z"
        fill={color}
      />
    </svg>
  );
};
