import React, { ReactNode, forwardRef } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Button, Flex, FlexProps, HStack } from '@chakra-ui/react';
import {
  removeWidget,
  setCardSize,
  useAppDispatch,
} from '@spartalabs/pdc-core';

interface CardsProps extends FlexProps {
  rowIndex: number;
  cardId: number;
  dragHandler?: DraggableProvided;
  size?: string;
  canBeHalf?: boolean;
  children: ReactNode;
}

export const ResizableCard = forwardRef<null, CardsProps>((props, ref) => {
  const dispatch = useAppDispatch();
  const { rowIndex, cardId, size, canBeHalf, dragHandler, children, ...rest } =
    props;

  return (
    <Flex
      ref={ref}
      minW={props.minW ?? 293}
      minH={props.minH ?? 136}
      flexDir="column"
      justifyContent="center"
      bg="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
      border="1px solid"
      borderColor="grey.200"
      borderRadius={4}
      py="12px"
      px={4}
      w={size}
      {...dragHandler?.dragHandleProps}
      {...rest}
    >
      <HStack alignSelf="flex-end">
        {canBeHalf && size === '100%' && (
          <Button
            onClick={() =>
              dispatch(setCardSize({ rowIndex, id: cardId, size: '50%' }))
            }
          >
            50%
          </Button>
        )}
        {size === '50%' && (
          <Button
            onClick={() =>
              dispatch(setCardSize({ rowIndex, id: cardId, size: '100%' }))
            }
          >
            100%
          </Button>
        )}
        <Button
          onClick={() => dispatch(removeWidget({ rowIndex, id: cardId }))}
        >
          remover
        </Button>
      </HStack>

      {children}
    </Flex>
  );
});

ResizableCard.displayName = 'ResizableCard';

export default ResizableCard;
