import React, { useCallback, useState } from 'react';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useAppSelector, useImportBiMutation } from '@spartalabs/pdc-core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ScheduleFile from 'assets/files/Planilha_Padrao_BI.xlsx';
import trashSvg from 'assets/svg/trash.svg';
import Onboarding from 'components/Onboarding';
import { useCustomToast } from 'hooks';
import { availabilityTexts } from 'utils/texts';

interface FileProps {
  file: File | null;
  name: string;
  path: string | null;
}

export const UploadModal: React.FC<{
  isOnboard: boolean;
  type: 'preventive' | 'availability';
}> = ({ isOnboard, type }) => {
  const { handleToast } = useCustomToast();
  const [sheet, setSheet] = useState<FileProps | null>(null);
  const [isDownload, setIsdDownload] = useState<boolean>(true);

  const [importBi, { isLoading }] = useImportBiMutation();

  const permissions = useAppSelector(
    state => state.authSlicer.user.permissions,
  );

  const handleSubmit = useCallback(async () => {
    if (!sheet?.file) return;
    try {
      const formData = new FormData();

      formData.append('arquivo', sheet.file);

      await importBi(formData).unwrap();

      setIsdDownload(false);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleToast({
        step: 4,
        action: error?.data?.Titulo,
        helpText: error?.data?.Conteudo,
      });
    }
  }, [sheet]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const newPreviewImages = Array.from(selectedFiles).map(file => {
        const reader = new FileReader();

        return new Promise<string>((resolve, reject) => {
          reader.onload = function (event) {
            if (event.target?.result) {
              resolve(event.target.result.toString());
            } else {
              reject(new Error('Failed to read the file.'));
            }
          };

          reader.onerror = function (event) {
            reject(
              event.target?.error ?? new Error('Failed to read the file.'),
            );
          };

          reader.readAsDataURL(file as Blob);
        });
      });

      Promise.all(newPreviewImages)
        .then(result => {
          const names = Array.from(selectedFiles).map(
            (file: File) => file.name,
          );
          setSheet({
            file: selectedFiles[0],
            name: names[0],
            path: result[0],
          });
        })
        .catch(() =>
          handleToast({
            step: 4,
            action: 'fazer o upload do seu arquivo',
          }),
        );
    }
  };

  const handleClose = () => {
    setIsdDownload(true);
    onClose();
    setSheet(null);
  };

  // if (!permissions?.includes('importbi')) return <></>;

  return (
    <Flex justifyContent="end" w="100%">
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => handleClose()}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={{ base: '80%', sm: isDownload ? '532px' : '376px' }}>
          {isDownload && (
            <ModalHeader p="0px" mt="36px" mb="12px">
              <Text textAlign="center" textColor="button">
                Importar planilha
              </Text>
            </ModalHeader>
          )}

          <ModalBody
            mt={isDownload ? '0px' : '40px'}
            mx={{ base: '0px', sm: '22px' }}
          >
            {isDownload ? (
              <>
                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  borderColor="text.100"
                  borderStyle="dashed"
                  borderWidth="1px"
                  rounded="md"
                  shadow="sm"
                  role="group"
                  py="15px"
                  px="24px"
                  h="54px"
                  transition="all 150ms ease-in-out"
                  _hover={{
                    shadow: 'md',
                  }}
                  position="relative"
                >
                  <Input
                    data-cy="file-input"
                    cursor="pointer"
                    type="file"
                    h="100%"
                    width="100%"
                    position="absolute"
                    multiple
                    top="0"
                    left="0"
                    bottom="0"
                    opacity="0"
                    aria-hidden="true"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    border="3px solid red"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    onChange={e => handleFileChange(e)}
                  />
                  <Text fontSize="12px" color="text.100">
                    Importar planilha de dados
                  </Text>

                  <Flex bg="grey.200" py="8px" px="10px" borderRadius="8px">
                    <Text fontSize="12px" fontWeight="normal" color="black">
                      Importar
                    </Text>
                  </Flex>
                </Flex>
                {sheet?.file ? (
                  <Flex mb="20px" alignItems="center" w="100%" mt="16px">
                    <Box>
                      <Icon
                        mt="4px"
                        color="black"
                        width="20px"
                        height="20px"
                        as={AiOutlineFileExcel}
                      />
                    </Box>
                    <Text
                      noOfLines={1}
                      ml="4px"
                      color="black"
                      wordBreak="break-all"
                    >
                      {sheet.name}
                    </Text>
                    <Button
                      bg="transparent"
                      p="0"
                      _hover={{ bg: 'transparent' }}
                      onClick={() => setSheet(null)}
                    >
                      <Image src={trashSvg} />
                    </Button>
                  </Flex>
                ) : (
                  <Text mb="20px" fontSize="14px" color="text.300" mt="16px">
                    IMPORTANTE: O uso da planilha modelo é obrigatório para
                    validações dos dados
                  </Text>
                )}
              </>
            ) : (
              <Center w="full" display="flex" flexDir="column">
                <Icon
                  p="16px"
                  borderRadius="100px"
                  bg="grey.200"
                  w="96px"
                  h="96px"
                  color="ocean.100"
                  as={HiCheck}
                />
                <Text
                  mt="16px"
                  color="text.100"
                  fontSize="24px"
                  fontWeight={500}
                >
                  Sucesso!
                </Text>
                <Text
                  textAlign="center"
                  w="90%"
                  aria-hidden="true"
                  as="label"
                  color="text.300"
                >
                  Os itens foram enviados com sucesso
                </Text>
              </Center>
            )}

            <ModalCloseButton color="black" onClick={() => handleClose()} />
          </ModalBody>

          <ModalFooter
            p="0px"
            mb="40px"
            mx={{ base: '20px', sm: '46px' }}
            gap="30px"
            flexDirection="column"
            justifyContent="center"
          >
            {isDownload ? (
              <>
                <Button
                  w="100%"
                  as="a"
                  target="_blank"
                  download="PlanilhaModeloBI"
                  href={ScheduleFile}
                  border="1px solid"
                  borderColor="blue.0"
                  textColor="blue.0"
                  fontWeight={500}
                  variant="outline"
                >
                  Baixar planilha modelo
                </Button>

                <Button
                  isLoading={isLoading}
                  isDisabled={!sheet?.file}
                  alignSelf="end"
                  onClick={() => handleSubmit()}
                >
                  Continuar
                </Button>
              </>
            ) : (
              <Button
                mt="12px"
                onClick={() => handleClose()}
                borderColor="blue.0"
                color="mutable.100"
                border="1px solid"
                variant="outline"
                w={{ base: '256px', sm: '100%' }}
              >
                Voltar para relatórios
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Onboarding
        isOpen={isOnboard}
        step={3}
        contents={availabilityTexts.step3}
        placement="end"
        type={type}
        bg="white"
        mb="20px"
        borderRadius="6px"
      >
        <Button
          onClick={onOpen}
          alignSelf="end"
          leftIcon={<IoMdDownload size="16px" />}
          variant="outline"
        >
          Importar
        </Button>
      </Onboarding>
    </Flex>
  );
};
