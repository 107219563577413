export const colors = {
  blue: {
    0: '#00A3FF',
    100: '#56B9F2',
    200: '#2686BE',
    300: '#0b3f5d',
    400: '#E0E0F3',
    700: '#CFF8FF',
    800: '#396AFF',
    900: '#E7EDFF',
  },

  tag: {
    100: '#EFF8FF',
    200: '#175CD3',
    300: '#3538CD',
    400: '#027A48',
    500: '#6941C6',
    600: '#C11574',
    700: '#FF4130',
    800: '#FF9757',
    900: '#FFEAAA',
    1000: '#96B1FF',
    1100: '#026AA2',
    1200: '#344054',
  },

  ocean: {
    100: '#0E3850',
    200: '#184E6D',
    300: '#062537',
    400: '#3C6278',
    500: '#234F6A',
    600: '#849FAD',
  },

  gray: {
    100: '#ACACAC',
    400: '#ACACAC',
    500: '#D9D9D9',
    600: '#828282',
    700: '#D3D3D4',
  },

  grey: {
    100: '#F2F2F2',
    200: '#ECECEC',
    300: '#E2E8F0',
    400: '#F7F9FB',
    500: '#ACACAC',
    600: '#747474',
    700: '#B8CAD5',
    800: '#344054',
    900: '#D0D5DD',
    1000: '#d3d3d3',
    1100: '#DFE1E6',
    1200: '#F0F9FF',
  },

  green: {
    100: '#22C38E',
    200: '#BBF7D0',
    300: '#166534',
    400: '#ECFDF3',
    500: '#027A48',
    600: '#7A9E86',
    700: '#C9FFD3',
    800: '#D9F7E7',
    900: '#4AD991',
    1000: '#4bae4f',
    1100: '#359847',
  },

  text: {
    100: '#303030',
    200: '#555555',
    300: '#707070',
    400: '#333333',
    500: '#A3A2A8',
    600: '#828282',
  },

  orange: {
    100: '#F38B00',
    200: '#FFBB38',
  },

  red: {
    100: '#B54708',
    200: '#FF0000',
    300: '#FEF3F2',
    400: '#ED1C24',
  },

  yellow: {
    100: '#F2C94C',
    200: '#FBF7EA',
    300: '#CCB05F',
    400: '#FFF4CE',
    500: '#F9CD45',
  },

  salmon: {
    100: '#FFDBD5',
    200: '#FFEDD4',
  },

  purple: {
    100: '#FBE0FF',
    200: '#5925DC',
    300: '#F4F3FF',
  },

  pink: {
    100: '#C11574',
    200: '#FDF2FA',
  },
  cyan: {
    100: '#03BAC6',
  },

  background: '#F5F5F5',
  white: '#FFFFFF',
  black: '#000000',
  button: '#0A3E5C',

  // Map Colors

  mapDisabled: '#ACACAC',
  map1Color: {
    default: '#9091c933',
    active: '#9091C9',
  },
  map2Color: {
    default: '#eaf58733',
    active: '#EAF587',
  },
  map3Color: {
    default: '#b4d86033',
    active: '#B4D860',
  },
  map4Color: {
    default: '#96a70033',
    active: '#96A700',
  },
  map5Color: {
    default: '#ba997a33',
    active: '#BA997A',
  },
  map6Color: {
    default: '#ffb06533',
    active: '#FFB065',
  },
  map7Color: {
    default: '#bfcd4033',
    active: '#BFCD40',
  },
  map8Color: {
    default: '#5d5d7933',
    active: '#5D5D79',
  },
  map9Color: {
    default: '#d6e9a933',
    active: '#d6e9a9',
  },
  map10Color: {
    default: '#f2ccff33',
    active: '#f2ccff',
  },
  services: {
    total: '#0A3E5C',
    preventive: '#F7D900',
    corrective: '#A2D9E5',
    sinister: '#00B3C5',
    others: '#F8EA99',
  },
  brazil: {
    age: '#56B9F2',
    km: '#00A3FF',
  },
  se_region: {
    age: '#F0C5FF',
    km: '#DD9AF4',
  },
  so_region: {
    age: '#8889BE',
    km: '#797AA9',
  },
  ne_region: {
    age: '#D6E07C',
    km: '#BCC56C',
  },
  no_region: {
    age: '#E49D5A',
    km: '#BE834B',
  },
  cw_region: {
    age: '#BECE96',
    km: '#A1B080',
  },
};
